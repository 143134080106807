import { FormControl } from '@mui/material';
import { FunctionComponent } from 'react';
import CustomTextField from '../CustomTextField';
import { ComboBoxItem } from './selectData';
import { CustomAutocomplete } from '../CustomAutocomplete';

interface ComboBoxProps {
  options: ComboBoxItem[];
  value: ComboBoxItem;
  label: string;
  dataTestId: string;
  groupBy?: (option: ComboBoxItem) => string;
  onChange: (newItem: ComboBoxItem) => void;
}

export const ComboBox: FunctionComponent<ComboBoxProps> = ({
  options,
  value,
  label,
  dataTestId,
  groupBy,
  onChange
}) => {
  return (
    <FormControl>
      <CustomAutocomplete
        dataTestId={`${dataTestId}-combo-box`}
        options={options}
        onChange={(_, value) => {
          const newSelectedValue = options.find((i) => i?.value === value?.value) ?? {
            displayValue: '',
            value: ''
          };
          onChange(newSelectedValue);
        }}
        getOptionDisabled={(o) => o.disabled ?? false}
        value={value}
        getOptionLabel={(o) => o.displayValue}
        groupBy={(o) => groupBy?.(o) ?? ''}
        renderInput={(params) => (
          <CustomTextField
            dataTestId={`${dataTestId}-combo-box`}
            label={label}
            {...params}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};
