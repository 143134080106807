import { PATH_DASHBOARD } from '../../routes/paths';
import Icon from 'components/icons/Icon';
import { Access } from 'guards/AccessGuard';

const ICONS = {
  ordersIcon: <Icon type="orderandusage" />,
  organization: <Icon type="account" />,
  dashboard: <Icon type="dashboard" />,
  campaign: <Icon type="campaigns" />,
  employee: <Icon type="employees" />,
  company: <Icon type="companies" />,
  respondents: <Icon type="respondents" />,
  pGapIcon: <Icon type="pgap" />,
  ivrIcon: <Icon type="ivr" />,
  reportIcon: <Icon type="reports" />,
  reviewIcon: <Icon type="reviews" />,
  integrationStoreIcon: <Icon type="integrationstore" />,
  productConfigurationsIcon: <Icon type="productconfigurations" />
};

export type SidebarItem = {
  title: string;
  path: string;
  icon?: JSX.Element;
  access?: keyof typeof Access;
};

export const getSidebarConfig = (
  integrationsEnabledByFeatureFlag: SidebarItem[]
): {
  subheader: string;
  items: SidebarItem[];
}[] => [
  {
    subheader: 'General',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Campaigns',
        path: PATH_DASHBOARD.campaigns.root,
        icon: ICONS.campaign
      },
      { title: 'Employees', path: PATH_DASHBOARD.employees.root, icon: ICONS.employee },
      { title: 'Respondents', path: PATH_DASHBOARD.respondents.root, icon: ICONS.respondents },
      { title: 'Companies', path: PATH_DASHBOARD.companies.root, icon: ICONS.company },
      { title: 'Reports', path: PATH_DASHBOARD.reports.root, icon: ICONS.reportIcon },
      {
        title: 'P-Gap',
        path: PATH_DASHBOARD.pGap.root,
        icon: ICONS.pGapIcon,
        access: 'PagePGap'
      },
      {
        title: 'Reviews',
        path: PATH_DASHBOARD.reviews.root,
        icon: ICONS.reviewIcon,
        access: 'PageReviews'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Account',
        path: PATH_DASHBOARD.organization.root,
        icon: ICONS.organization
      },
      {
        title: 'Orders',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.ordersIcon,
        access: 'PageOrders'
      },
      {
        title: 'Product Configurations',
        path: PATH_DASHBOARD.productConfigurations.root,
        icon: ICONS.productConfigurationsIcon,
        access: 'PageProductConfiguration'
      }
    ]
  },
  {
    subheader: 'Integrations',
    items: [
      {
        title: 'Integration Store',
        path: PATH_DASHBOARD.integrationStore.root,
        icon: ICONS.integrationStoreIcon,
        access: 'PageIntegrationStore'
      },
      {
        title: 'IVR',
        path: PATH_DASHBOARD.ivr.root,
        icon: ICONS.ivrIcon,
        access: 'PageIVR'
      },
      {
        title: 'Outlook',
        path: PATH_DASHBOARD.outlook.root,
        access: 'PageOutlook'
      },
      {
        title: 'etrack1',
        path: PATH_DASHBOARD.eTrack1.root,
        access: 'PageEtrack1'
      },
      {
        title: 'Zapier',
        path: PATH_DASHBOARD.zapier.root,
        access: 'PageZapier'
      },
      ...integrationsEnabledByFeatureFlag
    ]
  }
];
