import Page from '../../components/util/Page';
import { Box, Card, Tab, Tabs, Link, Typography } from '@mui/material';
import { useMemo } from 'react';
import CustomButton from 'components/util/CustomButton';
import SurveyOrderList from './components/surveyOrder/SurveyOrderList';
import Icon from 'components/icons/Icon';
import { useNavigate, useParams } from 'react-router';
import { TabContext, TabPanel } from '@mui/lab';
import { capitalCase } from 'change-case';
import { PATH_DASHBOARD } from 'routes/paths';
import ProductOrderList from './components/productOrder/ProductOrderList';
import BillableHoursOrderList from './components/billableHoursOrder/BillableHoursOrderList';
import AccessGuard from 'guards/AccessGuard';
import { useLicense } from 'hooks/useLicense';
import useAuth from 'hooks/useAuth';

export enum OrdersTab {
  SURVEY_ORDERS = 'survey_orders',
  PRODUCT_ORDERS = 'product_orders',
  BILLABLE_HOURS_ORDERS = 'billable_hours_orders'
}

const Orders = () => {
  const navigate = useNavigate();
  const { tab = OrdersTab.SURVEY_ORDERS } = useParams();
  const { license, licenseLoading } = useLicense();
  const { selectedOrg } = useAuth();

  const TABS = useMemo(() => {
    return [
      {
        value: OrdersTab.SURVEY_ORDERS,
        icon: <Icon type="campaigns" />,
        component: <SurveyOrderList />
      },
      {
        value: OrdersTab.PRODUCT_ORDERS,
        icon: <Icon type="integrationstore" />,
        component: <ProductOrderList />
      },
      {
        value: OrdersTab.BILLABLE_HOURS_ORDERS,
        icon: <Icon type="clock" />,
        component: <BillableHoursOrderList />
      }
    ];
  }, []);

  const onTabChange = (newTab: OrdersTab) => {
    navigate(`${PATH_DASHBOARD.orders.root}/tabs/${newTab}`);
  };

  const onAddOrder = () => {
    if (tab === OrdersTab.SURVEY_ORDERS) {
      navigate(PATH_DASHBOARD.orders.newSurveyOrder);
    }
    if (tab === OrdersTab.BILLABLE_HOURS_ORDERS) {
      navigate(PATH_DASHBOARD.orders.newBillableHoursOrder);
    }
    if (tab === OrdersTab.PRODUCT_ORDERS) {
      navigate(PATH_DASHBOARD.orders.newProductOrder);
    }
  };

  const addOrderText = useMemo(() => {
    if (tab === OrdersTab.SURVEY_ORDERS) {
      return 'Add Survey Order';
    }
    if (tab === OrdersTab.PRODUCT_ORDERS) {
      return 'Add Product Order';
    }
    return 'Add Billable Hours Order';
  }, [tab]);
  return !license && !licenseLoading && selectedOrg?.organizationId ? (
    <Page title="Orders">
      <Card sx={{ p: 3 }}>
        <Typography>
          This organization has no license. A license is required to create orders.
        </Typography>
        <Link href={`${PATH_DASHBOARD.admin.root}/license`}>
          Click here to start creating a license.
        </Link>
      </Card>
    </Page>
  ) : (
    <Page
      title="Orders"
      stickyHeaderContent={[
        [
          <AccessGuard key="addOrderAccess" access={'SuperAdmin'}>
            <CustomButton dataTestId="add-order" variant={'contained'} onClick={() => onAddOrder()}>
              {addOrderText}
            </CustomButton>
          </AccessGuard>
        ]
      ]}
    >
      <Card sx={{ p: 3 }}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(_, value) => onTabChange(value as OrdersTab)}
          >
            {TABS.map((tab, index) => {
              return (
                <Tab
                  disableRipple
                  key={index}
                  label={capitalCase(tab.value)}
                  value={tab.value}
                  icon={tab.icon}
                />
              );
            })}
          </Tabs>
          <Box sx={{ mb: 3 }} />
          {TABS.map((tab, index) => (
            <TabPanel key={index} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </Card>
    </Page>
  );
};

export default Orders;
