import { Card, CardHeader, Stack } from '@mui/material';
import { Text } from 'components/text/Text';
import { CustomLoadingButton } from 'components/util/CustomButton';
import CustomSwitch from 'components/util/CustomSwitch';
import { Role } from 'guards/AccessGuard';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import useCustomDispatch from 'redux/dispatch';
import {
  getAuthenticationConfiguration,
  upsertConfiguration
} from 'redux/slices/authenticationConfiguration';
import { RootState, useSelector } from 'redux/store';
import { useTranslator } from 'translation/useTranslator';

const componentDataTestId = 'authentication-configuration';

export const AuthenticationConfiguration = () => {
  const translator = useTranslator();
  const auth = useAuth();
  const customDispatch = useCustomDispatch();
  const { configuration, isLoading } = useSelector(
    (state: RootState) => state.authenticationConfiguration
  );
  const [disableFormLogin, setDisableFormLogin] = useState(configuration.disableFormLogin);

  useEffect(() => {
    customDispatch({
      action: getAuthenticationConfiguration,
      disableSuccessMessage: true,
      onSuccess: () => {
        setDisableFormLogin(configuration.disableFormLogin);
      }
    });
  }, [configuration.disableFormLogin, customDispatch]);

  return auth.user?.role === Role.CompanyUser ? null : (
    <Card sx={{ padding: 2, marginBottom: 2, width: '100%' }}>
      <CardHeader
        sx={{ p: 0 }}
        title={translator.authenticationConfiguration()}
        action={
          <CustomLoadingButton
            disabled={disableFormLogin === configuration.disableFormLogin}
            loading={isLoading}
            dataTestId={`${componentDataTestId}-save`}
            variant="contained"
            onClick={() => {
              customDispatch({
                action: upsertConfiguration,
                actionParameters: { configuration: { ...configuration, disableFormLogin } }
              });
            }}
          >
            {translator.save()}
          </CustomLoadingButton>
        }
      />
      <Stack>
        <CustomSwitch
          label={translator.disableFormLogin()}
          switchProps={{
            disabled: isLoading,
            checked: disableFormLogin,
            onChange: (event, value) => {
              setDisableFormLogin(value);
            }
          }}
        />
        <Text variant="caption">{translator.disableFormLoginDescription()}</Text>
      </Stack>
    </Card>
  );
};
