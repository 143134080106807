import { Tab, TabProps } from '@mui/material';
import { FunctionComponent } from 'react';

type CustomTabProps = {
  dataTestId: string;
} & TabProps;

export const CustomTab: FunctionComponent<CustomTabProps> = ({ dataTestId, ...rest }) => {
  return <Tab {...rest} data-testid={`${dataTestId}-tab`} />;
};
