export type IntegrationsState = {
  isLoading: Loading;
  integrations: Integration[];
  hasError: boolean;
};

type Loading = {
  [key: string]: boolean;
};

export type Integration = {
  id: number;
  name: string;
  description: string;
  logo: string | null;
  enabled: boolean;
  productCategory: ProductCategory;
};

export enum ProductCategory {
  Subscription = 1,
  Plugin = 2,
  Connector = 3,
  Module = 4
}

export enum IntegrationType {
  //Plugin
  Plugin = 1,
  OutlookPlugin = 2,
  PuzzelPlugin = 3,
  ETrack1Plugin = 4,
  SalesforcePlugin = 5,
  CapturiPlugin = 6,
  MiralixPlugin = 7,
  AdversusPlugin = 8,
  TrioPlugin = 9,
  DynamicsPlugin = 10,
  LimePlugin = 11,
  SuperOfficePlugin = 12,
  ChannelCRMPlugin = 13,
  TopdeskPlugin = 14,
  IpvisionPlugin = 15,
  DstnyPlugin = 16,
  FreshServicePlugin = 17,
  PipedrivePlugin = 18,
  ZylincPlugin = 19,
  FlexfonePlugin = 20,
  ZendeskPlugin = 21,
  EnreachCampaignsPlugin = 22,
  DixaPlugin = 23,
  TeliaTouchpointPlugin = 24,
  ThreeKontaktPlugin = 25,

  // Module
  PgapModule = 26,
  PopUpModule = 27,
  TelavoxModule = 28,
  EmbedModule = 29,
  AdvancedSurveyModule = 30,
  OnlineReviewModule = 31,
  SmsModule = 32,
  ApiFlowModule = 33,

  // Connector
  Connector = 34,
  ZendeskConnector = 35,
  PipedriveConnector = 36,
  PowerBIConnector = 37,
  HeroBaseConnector = 38,
  ThreeKontaktConnector = 39,
  JiraConnector = 40,
  PardotConnector = 41,
  ApsisConnector = 42,
  SftpServerConnector = 43,
  PowerDashboardsConnector = 44,
  ZapierConnector = 45,
  DixaConnector = 46,

  // Subscription
  LicenseSubscription = 47,
  AdminUsersSubscription = 48,
  SurveyChannelsSubscription = 49,

  FeedbackApp = 50
}
