import { useEffect, useState } from 'react';
import CustomButton from 'components/util/CustomButton';
import { Box, Paper, Container, Typography } from '@mui/material';

type ErrorFallbackProps = {
  error: Error;
};
const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const [isChunkError, setIsChunkError] = useState(false);
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      setIsChunkError(true);
    }
  }, [error]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.neutral'
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          {isChunkError && (
            <Typography variant="body1" color="textSecondary">
              It looks like a new version of the app is available. Please refresh your browser to
              get the latest updates.
            </Typography>
          )}
          {error?.message && (
            <Typography variant="body2" color="error" sx={{ marginTop: '10px' }}>
              Error: {error.message}
            </Typography>
          )}
          <CustomButton
            dataTestId="refresh-page"
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            onClick={() => window.location.reload()}
          >
            Click here to refresh
          </CustomButton>
        </Paper>
      </Container>
    </Box>
  );
};

export default ErrorFallback;
