import { Typography, TypographyProps } from '@mui/material';
import { FunctionComponent } from 'react';

type TextProps = {
  dataTestId?: string;
} & TypographyProps;

export const Text: FunctionComponent<TextProps> = ({ dataTestId, ...rest }) => {
  return (
    <Typography {...rest} data-testid={dataTestId ? `${dataTestId}-text` : 'text'}>
      {rest.children}
    </Typography>
  );
};
