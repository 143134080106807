import { Typography } from '@mui/material';
import { License } from '../../../@types/license';
import CustomButton from 'components/util/CustomButton';
import { ComboBox } from 'components/util/select/ComboBox';
import { FunctionComponent, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { useTranslator } from 'translation/useTranslator';
import { useLicense } from 'hooks/useLicense';
import { ComboBoxItem } from 'components/util/select/selectData';

const componentDataTestId = 'link-license';

interface LinkLicenseProps {
  selectedLicense: ComboBoxItem | undefined;
  isReassignment?: boolean;
  onChange: (item: ComboBoxItem) => void;
  onSave?: () => void;
}

export const LinkLicense: FunctionComponent<LinkLicenseProps> = ({
  selectedLicense,
  isReassignment,
  onChange,
  onSave
}) => {
  const translator = useTranslator();
  const { assignLicenseToOrg, licenseLoading, license } = useLicense();
  const [licenses, setLicenses] = useState<License[] | undefined>([]);
  const [loading, setLoading] = useState(true);

  const getLicenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/license/list');
      setLicenses(response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setLicenses(undefined);
    }
  };

  useEffect(() => {
    getLicenses();
  }, []);

  const options = licenses?.map((l): ComboBoxItem => {
    const displayValue = translator.licenseDisplayValue(
      l.customerName ?? translator.noCustomerName(),
      l.customerVat
    );
    const value = l.id;
    return { displayValue, value, group: l.customerName ?? translator.noCustomerName() };
  });

  const handleSave = async () => {
    await assignLicenseToOrg({ licenseId: selectedLicense?.value });
    onSave?.();
  };
  return (
    <>
      <Typography>
        {isReassignment
          ? translator.assignLicenseHelpMessage()
          : translator.createOrLinkLicenseHelpMessage()}
      </Typography>
      <ComboBox
        dataTestId={`${componentDataTestId}-licenses`}
        label={translator.licenses()}
        value={selectedLicense ?? { displayValue: '', value: '' }}
        onChange={onChange}
        options={options ?? []}
      />
      <CustomButton
        dataTestId={`${componentDataTestId}-save`}
        variant="contained"
        disabled={
          !selectedLicense?.value ||
          loading ||
          licenseLoading ||
          license?.id === selectedLicense.value
        }
        onClick={handleSave}
      >
        {translator.save()}
      </CustomButton>
    </>
  );
};
