import { Box, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { useEffect, useMemo, useState } from 'react';
import APIKeys from './components/APIKeys';
import OrganizationGeneral from './components/OrganizationGeneral';
import OrganizationUsers from './components/OrganizationUsers';
import Page, { StickyHeaderContent } from '../../components/util/Page';
import {
  getAPIKeys,
  getOrganizationAnalytics,
  getOrganizationUsers
} from '../../redux/slices/organization';
import { useNavigate, useParams } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

import { Access } from '../../@types/claims';
import ClaimBasedGuard, {
  ClaimTypePosition,
  getClaimsFromAccessType,
  useHasAccess
} from 'guards/ClaimBasedGuard';
import OrganizationSenders from './components/OrganizationSenders';
import useCustomDispatch from 'redux/dispatch';
import CustomButton from 'components/util/CustomButton';
import Providers from './components/Providers';
import Icon from 'components/icons/Icon';
import DataPrivacy from './components/DataPrivacy';
import Relay from './components/Relay';
import HumanVerification from './components/HumanVerification';

export enum OrganisationTab {
  GENERAL = 'general',
  API_KEYS = 'api_keys',
  PROVIDERS = 'providers',
  USERS = 'users',
  SENDERS = 'senders',
  DATA_PRIVACY = 'data_privacy',
  RELAY = 'relay',
  HUMAN_VERIFICATION = 'human_verification'
}

export default function Organization() {
  const customDispatch = useCustomDispatch();
  const { tab = OrganisationTab.GENERAL } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    customDispatch({ action: getOrganizationAnalytics, disableSuccessMessage: true });

    customDispatch({ action: getAPIKeys, disableSuccessMessage: true });

    customDispatch({ action: getOrganizationUsers, disableSuccessMessage: true });
  }, [customDispatch]);

  const accessSenders = useHasAccess(getClaimsFromAccessType(Access.PAGE_SENDERS));

  const onTabChange = (newTab: OrganisationTab) => {
    navigate(`${PATH_DASHBOARD.organization.root}/${newTab}`);
  };

  //user header states
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDeleteUsers, setOpenDeleteUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  //sender header state
  const [displayAddSender, setDisplayAddSender] = useState(false);

  const TABS = useMemo(
    () => [
      {
        value: OrganisationTab.GENERAL,
        icon: <Icon type="general" />,
        component: <OrganizationGeneral />
      },
      {
        value: OrganisationTab.USERS,
        icon: <Icon type="users" />,
        component: (
          <OrganizationUsers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            openAddUser={openAddUser}
            setOpenAddUser={setOpenAddUser}
            openDeleteUsers={openDeleteUsers}
            setOpenDeleteUsers={setOpenDeleteUsers}
          />
        ),
        headerContent: [
          [
            selectedUsers.length > 0 && (
              <CustomButton
                dataTestId="delete-users"
                color={'error'}
                variant={'contained'}
                onClick={() => setOpenDeleteUsers(true)}
              >
                Delete user{selectedUsers.length > 1 && 's'}
              </CustomButton>
            ),
            <CustomButton
              dataTestId="add-user"
              key="addUser"
              variant={'contained'}
              onClick={() => setOpenAddUser(true)}
            >
              Add user
            </CustomButton>
          ]
        ]
      },
      {
        value: OrganisationTab.API_KEYS,
        icon: <Icon type="key" />,
        component: <APIKeys />
      },
      {
        value: OrganisationTab.PROVIDERS,
        icon: <Icon type="providers" />,
        component: <Providers editProviders={true} />
      },
      {
        value: OrganisationTab.DATA_PRIVACY,
        icon: <Icon type="dataretention" />,
        component: <DataPrivacy />
      },
      {
        value: OrganisationTab.RELAY,
        icon: <Icon type="connection" />,
        component: <Relay />
      },
      {
        value: OrganisationTab.HUMAN_VERIFICATION,
        icon: <Icon type="robot" />,
        component: <HumanVerification />
      },
      {
        value: OrganisationTab.SENDERS,
        icon: <Icon type="senders" />,
        component: (
          <OrganizationSenders
            displayAddSender={displayAddSender}
            setDisplayAddSender={setDisplayAddSender}
          />
        ),
        claim: getClaimsFromAccessType(Access.PAGE_SENDERS),
        headerContent: [
          [
            <CustomButton
              dataTestId="add-sender"
              key="addSender"
              variant={'contained'}
              onClick={() => setDisplayAddSender(true)}
            >
              Add sender
            </CustomButton>
          ]
        ]
      }
    ],
    [selectedUsers, openAddUser, openDeleteUsers, displayAddSender]
  );

  const selectedTab = TABS.find((aTab) => aTab.value === tab);

  return (
    <Page
      title="Account"
      stickyHeaderContent={selectedTab?.headerContent as StickyHeaderContent[][]}
    >
      <div style={{ display: 'inline-grid' }}>
        <Tabs
          value={tab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => onTabChange(value as OrganisationTab)}
        >
          {TABS.map((tabItem) => (
            <Tab
              style={{
                visibility:
                  !tabItem.claim || accessSenders || tab === tabItem.value ? 'visible' : 'hidden'
              }}
              disableRipple
              key={tabItem.value}
              label={
                <ClaimBasedGuard
                  accessibleClaims={tabItem.claim}
                  displayDeniedMessage={false}
                  claimTypePosition={ClaimTypePosition.TOP_RIGHT}
                >
                  {capitalCase(tabItem.value)}
                </ClaimBasedGuard>
              }
              icon={tabItem.icon}
              value={tabItem.value}
            />
          ))}
        </Tabs>
      </div>

      <Box sx={{ mb: 5 }} />

      {TABS.map((tabItem) => {
        const isMatched = tabItem.value === tab;
        return (
          isMatched && (
            <ClaimBasedGuard key={tabItem.value} accessibleClaims={tabItem.claim}>
              <Box>{tabItem.component}</Box>
            </ClaimBasedGuard>
          )
        );
      })}
    </Page>
  );
}
