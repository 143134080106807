import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import CustomTextField from '../CustomTextField';

const componentDataTestId = 'integer';

interface IntegerInputProps {
  label: string;
  value: number;
  defaultValue?: number | null;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (v: number | string | null) => void;
  allowDecimal?: boolean;
  maxValue?: number;
  dataTestId: string;
}

export const IntegerInput: FunctionComponent<IntegerInputProps> = ({
  label,
  value,
  defaultValue = 0,
  error,
  helperText,
  disabled,
  required,
  onChange,
  allowDecimal = false,
  maxValue,
  dataTestId
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const disallowedKeys = useMemo(() => {
    const values = { e: 'e', E: 'E', '-': '-', '+': '+' };
    var keysMap = new Map(Object.entries(values));

    return keysMap;
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (disallowedKeys.has(e.key)) {
        e.preventDefault();
      }
    },
    [disallowedKeys]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let value = allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value);
      onChange(isNaN(value) ? '' : maxValue && value > maxValue ? maxValue : value);
    },
    [onChange, allowDecimal, maxValue]
  );

  return (
    <CustomTextField
      dataTestId={`${dataTestId}-${componentDataTestId}`}
      ref={inputRef}
      label={label}
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}
      required={required}
      onPaste={(e) => {
        e.preventDefault();
      }}
      fullWidth
      type="number"
      onChange={handleChange}
      onBlur={(e) => {
        if (e.target.value === '') {
          onChange(defaultValue);
        }
      }}
      onKeyDown={handleKeyDown}
    />
  );
};
