import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Theme } from '@material-ui/core';
import { Alert, AlertTitle, Tooltip } from '@mui/material';
import { AuthUser } from '../@types/authentication';
import SuperAdminIcon from 'pages/admin/components/SuperAdminIcon';
import BetaIcon from 'pages/admin/components/BetaIcon';
import { RootState, useSelector } from 'redux/store';
import { CLAIMS } from '../@types/claims';
import { isAccessible } from './ClaimBasedGuard';
import { Container } from '@mui/material';

//The highest level access must be at the top of the enum, and the lowest level access must be at the bottom of the enum
export enum Role {
  SuperAdmin = 'SuperAdmin',
  CompanyAdmin = 'CompanyAdmin',
  CompanyUser = 'CompanyUser'
}

export enum Access {
  /*
   * Pages
   */
  PagePGap = 'PagePGap',
  PageReviews = 'PageReviews',
  PageOrders = 'PageOrders',
  PageProductConfiguration = 'PageProductConfiguration',
  PageIntegrationStore = 'PageIntegrationStore',
  PageIVR = 'PageIVR',
  PageOutlook = 'PageOutlook',
  PageEtrack1 = 'PageEtrack1',
  PageZapier = 'PageZapier',
  PageDalux = 'PageDalux',

  /*
   * Features
   */
  FeatureAdvancedSurveyModule = 'FeatureAdvancedSurveyModule',

  /*
   * Modify data
   */
  // Daily operations
  OperationCreate = 'OperationCreate',
  OperationEdit = 'OperationEdit',
  OperationMerge = 'OperationMerge',
  OperationDelete = 'OperationDelete',
  // Account settings, global organization settings, integration setup
  ManagementCreate = 'ManagementCreate',
  ManagementEdit = 'ManagementEdit',
  ManagementMerge = 'ManagementMerge',
  ManagementDelete = 'ManagementDelete',

  /*
   * For everything only superadmins can do, that has no specific action defined
   */
  SuperAdmin = 'SuperAdmin'
}

enum Feature {
  PGap = 'features_pgap',
  Reviews = 'features_review',
  Ivr = 'features_ivr',
  Outlook = 'applications_outlook',
  Etrack1 = 'management_etrack1',
  AdvancedSurveyModule = 'management_surveyjs'
}

type PermissionDetails = {
  viewPermission: Role;
  editPermission?: Role;
  feature?: Feature;
  isBeta?: boolean;
  claims?: string[]; //to be removed once claims are no longer used
};

const accessPermision: Record<Access, PermissionDetails> = {
  /* Pages */
  [Access.PagePGap]: {
    viewPermission: Role.CompanyUser,
    feature: Feature.PGap,
    claims: [CLAIMS.features.pgap]
  },
  [Access.PageReviews]: {
    viewPermission: Role.CompanyUser,
    feature: Feature.Reviews,
    claims: [CLAIMS.features.review]
  },
  [Access.PageOrders]: {
    viewPermission: Role.SuperAdmin,
    isBeta: true,
    claims: [CLAIMS.beta]
  },
  [Access.PageProductConfiguration]: {
    viewPermission: Role.CompanyAdmin,
    isBeta: false
  },
  [Access.PageIntegrationStore]: {
    viewPermission: Role.SuperAdmin,
    isBeta: true,
    claims: [CLAIMS.beta]
  },
  [Access.PageIVR]: {
    viewPermission: Role.CompanyUser,
    feature: Feature.Ivr,
    claims: [CLAIMS.features.ivr]
  },
  [Access.PageOutlook]: {
    viewPermission: Role.CompanyUser,
    feature: Feature.Outlook,
    claims: [CLAIMS.applications.outlook]
  },
  [Access.PageEtrack1]: {
    viewPermission: Role.CompanyUser,
    feature: Feature.Etrack1,
    claims: [CLAIMS.management.etrack1]
  },
  [Access.PageZapier]: {
    viewPermission: Role.SuperAdmin,
    isBeta: true,
    claims: [CLAIMS.beta, CLAIMS.management.zapier]
  },
  [Access.PageDalux]: {
    viewPermission: Role.CompanyAdmin
  },

  /* Features */
  [Access.FeatureAdvancedSurveyModule]: {
    viewPermission: Role.CompanyUser,
    editPermission: Role.CompanyUser,
    feature: Feature.AdvancedSurveyModule,
    isBeta: true
  },

  /* Modify Data */
  // Daily operations
  [Access.OperationCreate]: { viewPermission: Role.CompanyUser, editPermission: Role.CompanyUser },
  [Access.OperationEdit]: { viewPermission: Role.CompanyUser, editPermission: Role.CompanyUser },
  [Access.OperationMerge]: { viewPermission: Role.CompanyUser, editPermission: Role.CompanyAdmin },
  [Access.OperationDelete]: {
    viewPermission: Role.CompanyUser,
    editPermission: Role.CompanyAdmin
  },
  // Account settings, global organization settings, integration setup
  [Access.ManagementCreate]: {
    viewPermission: Role.CompanyUser,
    editPermission: Role.CompanyAdmin
  },
  [Access.ManagementEdit]: { viewPermission: Role.CompanyUser, editPermission: Role.CompanyAdmin },
  [Access.ManagementMerge]: {
    viewPermission: Role.CompanyUser,
    editPermission: Role.CompanyAdmin
  },
  [Access.ManagementDelete]: {
    viewPermission: Role.CompanyUser,
    editPermission: Role.CompanyAdmin
  },

  /* SuperAdmin Actions */
  [Access.SuperAdmin]: { viewPermission: Role.SuperAdmin }
};

export const getAccess = ({
  user,
  access,
  currentClaims
}: {
  user: AuthUser;
  access: keyof typeof Access | undefined;
  currentClaims?: string[]; // legacy
}): {
  canView: boolean;
  canEdit: boolean;
  requiredEditRole?: Role;
  isBeta?: boolean;
} => {
  if (!access) return { canView: true, canEdit: true };

  //if claims are registered, use legacy claim logic
  if (access && accessPermision[access].claims) {
    const hasLegacyAccess = isAccessible(accessPermision[access].claims, currentClaims ?? []);
    return {
      canView: Boolean(hasLegacyAccess),
      canEdit: Boolean(hasLegacyAccess),
      requiredEditRole: Role.CompanyUser,
      isBeta: accessPermision[access].isBeta
    };
  }
  //end of legacy logic

  let currentRoleIndex = Object.values(Role).indexOf(user?.role);
  if (currentRoleIndex === -1) currentRoleIndex = 999;

  const isLockedFeature = accessPermision[access].feature
    ? user!.claims?.includes(String(accessPermision[access].feature))
    : false;

  const isBeta = accessPermision[access].isBeta;
  const requiredViewRole = accessPermision[access].viewPermission;
  const canView =
    currentRoleIndex <= Object.values(Role).indexOf(requiredViewRole) &&
    !isLockedFeature &&
    (!isBeta || user?.claims.includes('beta'));
  const requiredEditRole = accessPermision[access].editPermission;
  const canEdit =
    requiredEditRole === undefined ||
    currentRoleIndex <= Object.values(Role).indexOf(requiredEditRole);

  return { canView, canEdit, requiredEditRole, isBeta };
};

export const useAccess = (
  access: keyof typeof Access | undefined
): { canView: boolean; canEdit: boolean; requiredEditRole?: Role; isBeta?: boolean } => {
  const { user } = useAuth();

  const { claimsToIgnore, extraClaims } = useSelector((state: RootState) => state.admin);

  return getAccess({
    user: {
      ...user,
      claims: [...user!.claims, ...extraClaims].filter((claim) => !claimsToIgnore.includes(claim))
    },
    access,
    currentClaims: [...user!.claims, ...extraClaims].filter(
      (claim) => !claimsToIgnore.includes(claim)
    )
  });
};

enum AccessTagPosition {
  Right = 'right',
  TopRight = 'topRight'
}
type AccessGuardProps = {
  access: keyof typeof Access | undefined;
  children: ReactNode;
  displayAccessTag?: keyof typeof AccessTagPosition;
  displayNoAccess?: boolean;
};
const AccessGuard = ({ access, children, displayAccessTag, displayNoAccess }: AccessGuardProps) => {
  const { canView, canEdit, requiredEditRole, isBeta } = useAccess(access);
  const isSuperAdmin = access && accessPermision[access].viewPermission === Role.SuperAdmin;

  return !canView ? (
    displayNoAccess ? (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    ) : null
  ) : (
    <Tooltip
      title={
        !canEdit && requiredEditRole
          ? `You need to be a ${requiredEditRole} to perform this action.`
          : ''
      }
      placement="top"
    >
      <Wrapper block_edit={`${!canEdit}`}>
        <div className="guarded-content">
          {children}
          {displayAccessTag && (
            <AccessTagWrapper position={AccessTagPosition[displayAccessTag]}>
              {isBeta ? <BetaIcon /> : isSuperAdmin && <SuperAdminIcon />}
            </AccessTagWrapper>
          )}
        </div>
      </Wrapper>
    </Tooltip>
  );
};

export default AccessGuard;

const Wrapper = styled('div')(({ block_edit, theme }: { block_edit: string; theme?: Theme }) => {
  return {
    position: 'relative',
    '&:hover': {
      cursor: block_edit === 'true' ? 'not-allowed' : 'inherit'
    },
    '> .guarded-content': {
      pointerEvents: block_edit === 'true' ? 'none' : 'inherit'
    }
  };
});

const AccessTagWrapper = styled('div')(({ position }: { position?: AccessTagPosition }) => ({
  visibility: position ? 'visible' : 'hidden',
  position: 'absolute',
  top: position === AccessTagPosition.TopRight ? 0 : '50%',
  right: 0,
  transform:
    position === AccessTagPosition.TopRight ? 'translate(50%, -50%)' : 'translate(100%, -50%)',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: position === AccessTagPosition.Right ? '.2rem' : 0
}));
