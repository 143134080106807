import {
  Box,
  BoxProps,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import IntegrationImage from 'components/integration/IntegrationImage';
import { ReactNode } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarItem } from 'layouts/dashboard/SidebarConfig';
import AccessGuard, { Access, getAccess } from 'guards/AccessGuard';
import useAuth from 'hooks/useAuth';
import { AuthUser } from '../../@types/authentication';
import { useCurrentRole } from 'guards/ClaimBasedGuard';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled(({ visible, ...props }: { visible: string } & any) => (
  <ListSubheader disableSticky disableGutters {...props} visible={visible} />
))<{ visible: string }>(({ visible, theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
  opacity: visible === 'true' ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out'
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

function NavItem({
  item,
  isShow,
  dataTestId
}: {
  item: SidebarItem;
  isShow?: boolean | undefined;
  dataTestId: string;
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, access } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  return (
    <AccessGuard access={access}>
      <ListItemStyle
        data-testid={`${dataTestId}-sidebar-link`}
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
      >
        <ListItemIconStyle>
          {icon ||
            (title && (
              <IntegrationImage
                integrationName={title}
                style={{ filter: isActiveRoot ? 'none' : 'grayscale(80%)' }}
              />
            ))}
        </ListItemIconStyle>
        {isShow && (
          <AccessGuard access={access} displayAccessTag="Right">
            <ListItemText
              disableTypography
              primary={title}
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
            />
          </AccessGuard>
        )}
      </ListItemStyle>
    </AccessGuard>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: SidebarItem[];
  }[];
}

const isAnyItemViewable = (
  access: (keyof typeof Access | undefined)[],
  user: AuthUser,
  currentClaims?: string[]
): boolean => {
  for (let i = 0; i < access.length; i++) {
    const { canView } = getAccess({ user, access: access[i], currentClaims });
    if (canView) return true;
  }
  return false;
};

const formatLinkNameToTestId = (name: string): string => {
  name = name.toLowerCase();
  name = name.replace(' ', '-');
  return name;
};

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  const { user } = useAuth();
  const currentClaims = useCurrentRole();

  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { subheader, items } = list;

        return (
          isAnyItemViewable(list.items.map((item) => item.access).flat(), user, currentClaims) && (
            <List disablePadding key={index}>
              <ListSubheaderStyle visible={isShow.toString()}>{subheader}</ListSubheaderStyle>
              {items.map((item: SidebarItem) => (
                <NavItem
                  key={item.title}
                  item={item}
                  isShow={isShow}
                  dataTestId={formatLinkNameToTestId(item.title)}
                />
              ))}
            </List>
          )
        );
      })}
    </Box>
  );
}
