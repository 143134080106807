import { Grid, Stack, Tooltip } from '@mui/material';
import Icon from 'components/icons/Icon';
import { CustomLoadingButton } from 'components/util/CustomButton';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { FunctionComponent } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { resendUserInvite } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';

type EmailConfirmedProps = {
  userId: string;
  userEmail: string;
  emailConfirmed: boolean;
  center?: boolean;
};
const EmailConfirmed = ({
  userId,
  userEmail,
  emailConfirmed,
  center = true
}: EmailConfirmedProps) => {
  return (
    <Stack alignItems={center ? 'center' : ''} sx={{ width: 1, textAlign: 'center' }}>
      {emailConfirmed ? (
        <EnabledIcon tooltip="" />
      ) : (
        <>
          {center ? (
            <Grid container>
              <Grid item xs={4} />
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <DisabledIcon tooltip="" />
              </Grid>
              <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <ResendInviteButton userId={userId} userEmail={userEmail} />
              </Grid>
            </Grid>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DisabledIcon tooltip="" />
              <ResendInviteButton userId={userId} userEmail={userEmail} />
            </div>
          )}
        </>
      )}
    </Stack>
  );
};
interface ResendInviteButtonProps {
  userId: string;
  userEmail: string;
}

const ResendInviteButton: FunctionComponent<ResendInviteButtonProps> = ({ userId, userEmail }) => {
  const { loading } = useSelector((state: RootState) => state.organization);
  const customDispatch = useCustomDispatch();
  return (
    <Tooltip title="Resend invite">
      <div>
        <CustomLoadingButton
          dataTestId="resend-invite"
          loading={loading?.[`userInvite${userId}`]}
          startIcon={<Icon type="resendsurvey" />}
          color="primary"
          onClick={async (event) => {
            event.stopPropagation();
            customDispatch({
              action: resendUserInvite,
              actionParameters: { userId: userId, userEmail: userEmail }
            });
          }}
        />
      </div>
    </Tooltip>
  );
};

export default EmailConfirmed;
