import { Autocomplete, AutocompleteProps } from '@mui/material';
import { FunctionComponent } from 'react';

type CustomAutcompleteProps<T> = {
  dataTestId: string;
} & AutocompleteProps<T, boolean, boolean, boolean>;

export const CustomAutocomplete: FunctionComponent<CustomAutcompleteProps<any>> = ({
  dataTestId,
  ...rest
}) => {
  return <Autocomplete {...rest} data-testid={`${dataTestId}-autocomplete`} />;
};
