import releaseConfig from './releaseConfig';

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const isNotProduction = releaseConfig.environment !== 'production';

export const msalConfig = {
  auth: {
    clientId: 'c56820cf-9d7d-4238-96ac-96f46cbae060',
    authority: 'https://login.microsoftonline.com/organizations', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: isDev ? 'http://localhost:3000/login/microsoft' : releaseConfig.redirectUri,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://c56820cf-9d7d-4238-96ac-96f46cbae060/user_impersonation']
};
