import 'firebase/remote-config';
import { RemoteConfigKeyConfiguration } from './remoteConfigKey';
import useAuth from 'hooks/useAuth';
import { useRemoteConfig } from './useRemoteConfig';
import { isNotProduction } from 'authConfig';

export interface FeatureFlagConfig {
  enabled: boolean;
  enabledForOrganizations: string[];
}

interface FeatureFlagHook {
  isFeatureFlagEnabled: (keyConfiguration: RemoteConfigKeyConfiguration) => boolean;
}
export const useFeatureFlags = (): FeatureFlagHook => {
  const { selectedOrg } = useAuth();
  const { getRemoteConfigValue } = useRemoteConfig();

  const isFeatureFlagEnabled = (keyConfiguration: RemoteConfigKeyConfiguration): boolean => {
    if (keyConfiguration.type !== 'json') {
      return false;
    }
    const value = getRemoteConfigValue(keyConfiguration);
    if (value === null) {
      return false;
    }
    const config = value as FeatureFlagConfig;
    return isNotProduction && keyConfiguration.showInNonProductionEnvironments
      ? true
      : !!(
          config?.enabled &&
          config?.enabledForOrganizations?.includes(selectedOrg?.organizationId ?? '')
        );
  };
  return { isFeatureFlagEnabled };
};
