import {
  Box,
  ToggleButton,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Access } from '../../@types/claims';
import ClaimBasedGuard from 'guards/ClaimBasedGuard';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/util/Logo';
import NavSection from '../../components/util/NavSection';
import Scrollbar from '../../components/util/Scrollbar';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { getSidebarConfig } from './SidebarConfig';
import { PATH_DASHBOARD } from 'routes/paths';
import { getIntegrations } from 'redux/slices/integrations';
import useCustomDispatch from 'redux/dispatch';
import Icon from 'components/icons/Icon';
import { useTheme } from '@mui/material/styles';
import { useFeatureFlags } from 'utils/firebase/useFeatureFlags';
import { DaluxConfig } from 'utils/firebase/remoteConfigKey';

export const DRAWER_WIDTH = 280;
export const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Box
      sx={{
        px: 2.5
      }}
    >
      <Tooltip title="Mini Menu">
        <ToggleButton
          value={!collapseClick}
          selected={!collapseClick}
          onClick={onToggleCollapse}
          size="small"
        >
          <Icon type="pin" isText />
        </ToggleButton>
      </Tooltip>
    </Box>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const daluxEnabled = isFeatureFlagEnabled(DaluxConfig);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  //upon first render, load integrations to get any integration logos to render in the sidebar
  useEffect(() => {
    customDispatch({ action: getIntegrations, disableSuccessMessage: true });
  }, [customDispatch]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        overflowX: 'hidden'
      }}
    >
      <Stack
        sx={{
          pt: 1.5
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box component={RouterLink} to="/" style={{ width: COLLAPSE_WIDTH }}>
          <Logo sx={{ width: 30, marginInline: 'auto' }} />
        </Box>
        <div style={{ position: 'absolute', top: '1.5rem', right: 0 }}>
          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden>
        </div>
      </Stack>

      <NavSection
        navConfig={getSidebarConfig(
          daluxEnabled
            ? [
                {
                  title: 'Dalux',
                  path: PATH_DASHBOARD.dalux.root,
                  access: 'PageDalux'
                }
              ]
            : []
        )}
        isShow={!isCollapse || (isMobile && isOpenSidebar)}
      />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ p: 2 }} />

      <ClaimBasedGuard
        accessType={Access.PAGE_SUPER_ADMIN}
        displayDeniedMessage={false}
        ignoreToggledClaims={true}
      >
        <div
          style={{
            padding: '1rem',
            width: COLLAPSE_WIDTH,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            sx={{ width: 'min-content' }}
            onClick={() => navigate(PATH_DASHBOARD.admin.root)}
          >
            <Icon type="settings" />
          </IconButton>
        </div>
      </ClaimBasedGuard>
    </Scrollbar>
  );

  return (
    <>
      {collapseClick && <div style={{ width: COLLAPSE_WIDTH }} />}
      <RootStyle
        sx={{
          width: {
            lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
          },
          ...(collapseClick && {
            position: 'absolute'
          })
        }}
      >
        <MHidden width="lgUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                transition: 'width .2s',
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                ...(isCollapse && {
                  width: COLLAPSE_WIDTH
                }),
                ...(collapseHover && {
                  borderRight: 0,
                  backdropFilter: 'blur(6px)',
                  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                  boxShadow: (theme) => theme.customShadows.z20,
                  bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                })
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </>
  );
}
