import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Slider,
  ToggleButton,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import useSettings from 'hooks/useSettings';
import palette from 'theme/palette';
import { Form, FormikProvider, useFormik } from 'formik';
import { SnowfallProps } from 'react-snowfall';
import { ColorPicker } from 'material-ui-color';
import CustomButton from 'components/util/CustomButton';

const SettingsChristmas = () => {
  const { enableChristmas, isDecember, onChangeEnableChristmas } = useSettings();
  const [displayChristmasSettins, setDisplayChristmasSettings] = useState(false);

  return (
    <>
      {isDecember && (
        <>
          <Wrapper>
            <Tooltip title={`Turn ${enableChristmas ? 'Off' : 'On'} Julehygge`}>
              <StyledToggleButton
                value={enableChristmas}
                selected={enableChristmas}
                onChange={(_, value: boolean) => onChangeEnableChristmas(!value)}
              >
                <SnowIcon
                  icon={`tabler:christmas-tree${enableChristmas ? '' : '-off'}`}
                  color={
                    enableChristmas ? palette.common.christmas.green : palette.common.christmas.red
                  }
                />
              </StyledToggleButton>
            </Tooltip>
            <IconButton
              id="christmasSettingsButton"
              color="primary"
              size="medium"
              onClick={() => setDisplayChristmasSettings((prevState) => !prevState)}
              sx={{ display: 'none' }}
            >
              <Icon icon="material-symbols:settings" />
            </IconButton>
          </Wrapper>
          <ChristmasSettingsDialog
            display={displayChristmasSettins}
            hideDialog={() => setDisplayChristmasSettings(false)}
          />
        </>
      )}
    </>
  );
};

export default SettingsChristmas;

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    '#christmasSettingsButton': {
      display: 'flex'
    }
  }
}));

const StyledToggleButton = styled(ToggleButton)({
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent'
});

const SnowIcon = styled(Icon)(({ theme, color }: { color: string } & any) => ({
  height: '1.5rem',
  width: '1.5rem',
  '& > *': {
    fill: color
  }
}));

type ChristmasSettingsDialogProps = {
  display: boolean;
  hideDialog: () => void;
};
const ChristmasSettingsDialog = ({ display, hideDialog }: ChristmasSettingsDialogProps) => {
  const { snowflakeValues, onChangeSnowflakeValues } = useSettings();

  const formik = useFormik<Partial<SnowfallProps>>({
    enableReinitialize: true,
    initialValues: {
      radius: snowflakeValues?.radius || [0.5, 3.0],
      wind: snowflakeValues?.wind || [-0.5, 2.0],
      snowflakeCount: snowflakeValues?.snowflakeCount || 150,
      speed: snowflakeValues?.speed || [1.0, 3.0],
      color: snowflakeValues?.color || '#dee4fd'
    },
    onSubmit: async (values) => {
      onChangeSnowflakeValues(values);
      hideDialog();
    }
  });

  const { values, submitForm, handleSubmit, getFieldProps, setFieldValue } = formik;

  const windFrom = values.wind?.[0];
  const windTo = values.wind?.[1];

  const radiusFrom = values.radius?.[0];
  const radiusTo = values.radius?.[1];

  const speedFrom = values.speed?.[0];
  const speedTo = values.speed?.[1];

  return (
    <Dialog open={display} onClose={hideDialog} style={{ overflow: 'display' }}>
      <DialogTitle>Snow settings</DialogTitle>
      <DialogContent style={{ paddingTop: '2rem' }}>
        <Box style={{ width: '20rem', maxWidth: '100%' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <FormControlLabel
                control={
                  <Slider
                    style={{ overflow: 'display' }}
                    {...getFieldProps('wind')}
                    valueLabelDisplay="auto"
                    min={-5}
                    max={5}
                  />
                }
                label="Wind"
                labelPlacement="start"
                sx={{ mx: 0, width: '100%', justifyContent: 'space-between', gap: '.5rem' }}
              />
              {typeof windFrom === 'number' && typeof windTo === 'number' && (
                <Description>{`${windFrom > 0 ? windFrom : Math.abs(windFrom)}m/s${
                  windFrom !== 0 ? ` from ${windFrom > 0 ? 'west' : 'east'}` : ''
                }${
                  (windFrom !== windTo &&
                    ` to ${windTo > 0 ? windTo : Math.abs(windTo)}m/s${
                      windTo !== 0 ? ` from ${windTo > 0 ? 'west' : 'east'}` : ''
                    }`) ||
                  ''
                }`}</Description>
              )}

              <FormControlLabel
                control={
                  <Slider
                    style={{ overflow: 'display' }}
                    {...getFieldProps('radius')}
                    valueLabelDisplay="auto"
                    min={1}
                    max={20}
                  />
                }
                label="Size"
                labelPlacement="start"
                sx={{ mx: 0, width: '100%', justifyContent: 'space-between', gap: '.5rem' }}
              />
              {radiusFrom && radiusTo && (
                <Description>{`${radiusFrom}cm${
                  (radiusFrom !== radiusTo && ` to ${radiusTo}cm`) || ''
                }`}</Description>
              )}

              <FormControlLabel
                control={
                  <Slider
                    style={{ overflow: 'display' }}
                    {...getFieldProps('snowflakeCount')}
                    valueLabelDisplay="auto"
                    min={1}
                    max={1000}
                  />
                }
                label="Amount"
                labelPlacement="start"
                sx={{ mx: 0, width: '100%', justifyContent: 'space-between', gap: '.5rem' }}
              />
              {radiusFrom && radiusTo && (
                <Description>{`${values.snowflakeCount} snowflake${
                  values?.snowflakeCount !== undefined && values?.snowflakeCount > 1 ? 's' : ''
                }`}</Description>
              )}

              <FormControlLabel
                control={
                  <Slider
                    style={{ overflow: 'display' }}
                    {...getFieldProps('speed')}
                    valueLabelDisplay="auto"
                    min={1}
                    max={20}
                  />
                }
                label="Gravity"
                labelPlacement="start"
                sx={{ mx: 0, width: '100%', justifyContent: 'space-between', gap: '.5rem' }}
              />
              {speedFrom && speedTo && (
                <Description>{`${speedFrom}G${
                  (speedFrom !== speedTo && ` to ${speedTo}G`) || ''
                }`}</Description>
              )}

              <FormControlLabel
                control={
                  <ColorPicker
                    value={values.color}
                    disableAlpha
                    deferred
                    hideTextfield
                    onChange={(color) => {
                      setFieldValue('color', '#' + color.hex);
                    }}
                  />
                }
                label="Color"
                labelPlacement="start"
                sx={{ mx: 0, width: '100%', justifyContent: 'space-between', gap: '.5rem' }}
              />
            </Form>
          </FormikProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton
          dataTestId="christmas-dialog-close"
          variant="outlined"
          color="primary"
          onClick={hideDialog}
        >
          Close
        </CustomButton>
        <CustomButton
          dataTestId="christmas-dialog-save"
          type="submit"
          variant="contained"
          onClick={submitForm}
        >
          Save
        </CustomButton>
      </DialogActions>
      <SantaIcon icon="emojione-v1:santa-claus" width="7rem" />
    </Dialog>
  );
};

const Description = styled(Typography)({
  fontWeight: 400,
  fontSize: '.75rem',
  paddingBottom: '1rem'
});

const SantaIcon = styled(Icon)({
  position: 'absolute',
  bottom: 0,
  opacity: '.5',
  pointerEvents: 'none'
});
