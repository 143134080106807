import { Box, Card, Tab, Tabs, Typography, Tooltip } from '@mui/material';
import Page from 'components/util/Page';
import { Access } from '../../@types/claims';
import Organizations from './components/Organizations';
import { useNavigate, useParams } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import ClaimBasedGuard, { getClaimsFromAccessType, useHasAccess } from 'guards/ClaimBasedGuard';
import { capitalCase } from 'change-case';
import AdminGeneral from './components/AdminGeneral';
import SuperAdminIcon from './components/SuperAdminIcon';
import CustomButton from 'components/util/CustomButton';
import { useRef, useState } from 'react';
import AddOrganizationDialog from './components/AddOrganizationDialog';
import Icon from 'components/icons/Icon';
import AdminLicense from './components/AdminLicense';
import ToggleEdit from 'components/page/detailsPage/components/ToggleEdit';
import { useLicense } from 'hooks/useLicense';
import { useTranslator } from 'translation/useTranslator';
import { TranslationWithHtml } from 'components/text/TranslationWithHtml';

export enum AdminTab {
  GENERAL = 'general',
  ORGANIZATIONS = 'organizations',
  LICENSE = 'license'
}

export type OnUpdateActiveStateClick = {
  onClickUpdate?: () => void;
};

const Admin = () => {
  const translator = useTranslator();
  const { license } = useLicense();
  const { tab = AdminTab.GENERAL } = useParams();
  const navigate = useNavigate();
  const access = useHasAccess(getClaimsFromAccessType(Access.PAGE_SUPER_ADMIN), true);
  const [displayAddOrg, setDisplayAddOrg] = useState(false);
  const [displayAddLinkedOrg, setDisplayAddLinkedOrg] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);
  const organizationTabRef = useRef<OnUpdateActiveStateClick>(null);
  const [licenseEditable, setLicenseEditable] = useState(false);

  const TABS = [
    {
      value: AdminTab.GENERAL,
      icon: <Icon type="settings" width={20} height={20} />,
      component: <AdminGeneral />,
      claim: getClaimsFromAccessType(Access.PAGE_SUPER_ADMIN)
    },
    {
      value: AdminTab.ORGANIZATIONS,
      icon: <Icon type="organizations" width={20} height={20} />,
      component: <Organizations onOrganizationSelect={setSelectedOrgs} ref={organizationTabRef} />,
      claim: getClaimsFromAccessType(Access.PAGE_SUPER_ADMIN)
    },
    {
      value: AdminTab.LICENSE,
      icon: <Icon type="license" width={20} height={20} />,
      component: (
        <AdminLicense editable={licenseEditable} onSave={() => setLicenseEditable(false)} />
      ),
      claim: getClaimsFromAccessType(Access.PAGE_SUPER_ADMIN)
    }
  ];

  const onTabChange = (newTab: AdminTab) => {
    setSelectedOrgs([]);
    navigate(`${PATH_DASHBOARD.admin.root}/${newTab}`);
  };

  return (
    <>
      <Page
        title={translator.superAdmin()}
        stickyHeaderContent={[
          [
            tab === AdminTab.ORGANIZATIONS && (
              <Tooltip
                key="addLinkedOrganizationTooltip"
                title={!Boolean(license?.id) ? translator.ensureValidLicenseDescription() : ''}
              >
                <span>
                  <CustomButton
                    dataTestId="add-linked-organization"
                    key="addLinkedOrganization"
                    variant={'outlined'}
                    onClick={() => setDisplayAddLinkedOrg(true)}
                    disabled={!Boolean(license?.id)}
                  >
                    {translator.addLinkedOrganization()}
                  </CustomButton>
                </span>
              </Tooltip>
            ),

            tab === AdminTab.ORGANIZATIONS && (
              <CustomButton
                dataTestId="add-organization"
                key="addOrganization"
                variant={'contained'}
                onClick={() => setDisplayAddOrg(true)}
              >
                {translator.addOrganization()}
              </CustomButton>
            ),
            tab === AdminTab.ORGANIZATIONS && selectedOrgs.length > 0 && (
              <CustomButton
                dataTestId="update-organizations"
                key="updateOrganizations"
                variant={'contained'}
                onClick={() => organizationTabRef.current?.onClickUpdate?.()}
              >
                {translator.updateOrganizations()}
              </CustomButton>
            ),
            tab === AdminTab.LICENSE && (
              <ToggleEdit
                isEditable={licenseEditable}
                onToggleEditable={() => setLicenseEditable((prev) => !prev)}
              />
            )
          ]
        ]}
      >
        <Typography>
          <TranslationWithHtml
            translation={translator.onlyAccessibleForSuperAdminsTitle()}
            rawText
          />{' '}
          <SuperAdminIcon />
        </Typography>

        <Typography>{translator.onlyAccessibleForSuperAdminsDescription()}</Typography>

        <Box sx={{ p: 1 }} />

        <Card sx={{ p: 3 }}>
          <div style={{ display: 'inline-grid' }}>
            <Tabs
              value={tab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_, value) => onTabChange(value as AdminTab)}
            >
              {TABS.map((tabItem) => (
                <Tab
                  style={{
                    visibility:
                      !tabItem.claim || access || tab === tabItem.value ? 'visible' : 'hidden'
                  }}
                  disableRipple
                  key={tabItem.value}
                  label={capitalCase(tabItem.value)}
                  icon={tabItem.icon}
                  value={tabItem.value}
                />
              ))}
            </Tabs>
          </div>

          <Box sx={{ mb: 1 }} />

          {TABS.map((tabItem) => {
            const isMatched = tabItem.value === tab;
            return (
              isMatched && (
                <ClaimBasedGuard
                  key={tabItem.value}
                  accessibleClaims={tabItem.claim}
                  ignoreToggledClaims={true}
                >
                  <Box>{tabItem.component}</Box>
                </ClaimBasedGuard>
              )
            );
          })}
        </Card>
      </Page>
      <AddOrganizationDialog
        display={displayAddOrg || displayAddLinkedOrg}
        close={() => {
          setDisplayAddOrg(false);
          setDisplayAddLinkedOrg(false);
        }}
        isLinkedOrg={displayAddLinkedOrg}
      />
    </>
  );
};

export default Admin;
