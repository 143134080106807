import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import {
  AuthenticationConfiguration,
  AuthenticationConfigurationDataState
} from '../../@types/authenticationConfiguration';

const initialState: AuthenticationConfigurationDataState = {
  isLoading: false,
  configuration: { disableFormLogin: false, orgId: '', id: '' }
};

const slice = createSlice({
  name: 'authenticationConfiguration',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    addOrderSuccess(state, action) {
      state.isLoading = false;
    },

    getConfigSuccess(state, action) {
      state.isLoading = false;
      state.configuration = action.payload.configuration;
    },

    upsertAuthenticationConfigurationSuccess(state, action) {
      const newConfiguration = JSON.parse(action.payload.config.data);
      state.configuration = newConfiguration;
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getAuthenticationConfiguration() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('authentication-configuration');
      dispatch(slice.actions.getConfigSuccess({ configuration: response.data }));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Authentication configuration fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch Authentication configuration'
      });
    }
  };
}

type UpsertConfigurationProps = {
  configuration: AuthenticationConfiguration;
};
export function upsertConfiguration({ configuration }: UpsertConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      console.log('configuration', configuration);
      const response = await axios.put('authentication-configuration', configuration);
      dispatch(slice.actions.upsertAuthenticationConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Authentication configuration updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update Authentication configuration'
      });
    }
  };
}
