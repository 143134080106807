import { IconButton, TextFieldProps, Box, MenuItem, ToggleButton } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import HighlightOnFirstRender from '../HighligtOnFirstRender';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { TimeSpan } from '../../../@types/dashboard';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths
} from 'date-fns';
import { styled } from '@mui/styles';
import useCustomDispatch, { CustomDispachActionPromise } from 'redux/dispatch';
import Icon from 'components/icons/Icon';
import CustomTextField from '../CustomTextField';

const componentDataTestId = 'from-to-date-picker';

// Regular expression to check if the format is dd/MM/yyyy
const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

export const getFromToFilterFromTimespan = (
  timespan: TimeSpan,
  currentFrom?: any,
  currentTo?: any
) => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const dayInMs = 24 * 60 * 60 * 1000;
  const weekStartsOn = 1;

  let from, to;
  switch (timespan) {
    case TimeSpan.TODAY:
      from = startOfDay(currentDate);
      to = endOfDay(currentDate);
      break;
    case TimeSpan.YESTERDAY:
      const yeasterdayDate = new Date(currentTime - dayInMs);
      from = startOfDay(yeasterdayDate);
      to = endOfDay(yeasterdayDate);
      break;
    case TimeSpan.CURRENT_WEEK:
      from = startOfWeek(currentDate, { weekStartsOn });
      to = endOfWeek(currentDate, { weekStartsOn });
      break;
    case TimeSpan.LAST_WEEK:
      const aWeekAgoDate = new Date(currentTime - dayInMs * 7);
      from = startOfWeek(aWeekAgoDate, { weekStartsOn });
      to = endOfWeek(aWeekAgoDate, { weekStartsOn });
      break;
    case TimeSpan.LAST_30_DAYS:
      const thirtyDaysAgoDate = new Date(currentTime - dayInMs * 30);
      from = startOfDay(thirtyDaysAgoDate);
      to = endOfDay(currentDate);
      break;
    case TimeSpan.CURRENT_MONTH:
      from = startOfMonth(currentDate);
      to = endOfMonth(currentDate);
      break;
    case TimeSpan.LAST_MONTH:
      const aMonthAgoDate = subMonths(currentDate, 1);
      from = startOfMonth(aMonthAgoDate);
      to = endOfMonth(aMonthAgoDate);
      break;
    case TimeSpan.CURRENT_QUARTER:
      from = startOfQuarter(currentDate);
      to = endOfQuarter(currentDate);
      break;
    case TimeSpan.LAST_QUARTER:
      const threeMonthsAgoDate = subMonths(currentDate, 3);
      from = startOfQuarter(threeMonthsAgoDate);
      to = endOfQuarter(threeMonthsAgoDate);
      break;
    case TimeSpan.CURRENT_YEAR:
      from = startOfYear(currentDate);
      to = endOfYear(currentDate);
      break;
    case TimeSpan.LAST_YEAR:
      const aYearAgoDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      from = startOfYear(aYearAgoDate);
      to = endOfYear(aYearAgoDate);
      break;
    case TimeSpan.ALL_TIME:
      from = undefined;
      to = undefined;
      break;
    case TimeSpan.CUSTOM:
    default:
      from = currentFrom;
      to = currentTo;
      break;
  }

  return { from, to };
};

type FromToDatePickerProps = {
  timespan: TimeSpan;
  toDateFilter: number | undefined;
  fromDateFilter: number | undefined;
  updateDateFilter: ({
    from,
    to,
    timespan
  }: {
    from: any;
    to: any;
    timespan: TimeSpan;
  }) => () => CustomDispachActionPromise;
  maxTimeSpan?: TimeSpan;
  shouldUpdate?: number; //any changes to this value, triggers an update of the to and from dates according to the selected timespan
  enableDynamicWidth?: boolean;
  dataTestId: string;
};

const FromToDatePicker = ({
  timespan,
  toDateFilter,
  fromDateFilter,
  updateDateFilter,
  maxTimeSpan,
  shouldUpdate,
  enableDynamicWidth,
  dataTestId
}: FromToDatePickerProps) => {
  const customDispatch = useCustomDispatch();
  const [dateRange, setDateRange] = useState<DateRange<Date | null>>([
    fromDateFilter ? new Date(fromDateFilter) : null,
    toDateFilter ? new Date(toDateFilter) : null
  ]);

  const [displayCustomDateRange, setDisplayCustomDateRange] = useState(
    timespan === TimeSpan.CUSTOM
  );

  const debouncedUpdateDateFilter = useRef(
    debounce(async ({ from, to, timespan }: { from: any; to: any; timespan: TimeSpan }) => {
      customDispatch({
        action: updateDateFilter,
        actionParameters: { from, to, timespan },
        disableAllMessages: true
      });
    }, 300)
  ).current;

  //ref for saving the crurrent active dateRange. If the input is invalid onBlur, set this to the input values.
  // used as a ref, as the dateFilter is debounced and the dateRange is updated on change
  const currentActiveDateRangeRef = useRef(dateRange);
  const handleDateRangeChange = useRef(
    ({ from, to, timespan }: { from: any; to: any; timespan: TimeSpan }) => {
      currentActiveDateRangeRef.current = [from ?? null, to ?? null];
      debouncedUpdateDateFilter({ from, to, timespan });
    }
  ).current;

  const prevTimespan = useRef<TimeSpan | undefined>(undefined);
  useEffect(() => {
    if (timespan === TimeSpan.CUSTOM) return;
    if (prevTimespan.current === timespan) return;
    prevTimespan.current = timespan;
    const { from, to } = getFromToFilterFromTimespan(timespan);
    setDateRange([from ? new Date(from) : null, to ? new Date(to) : null]);

    handleDateRangeChange({
      from,
      to,
      timespan
    });
  }, [timespan, shouldUpdate, handleDateRangeChange]);

  const getInputProps = (params: TextFieldProps) => {
    return {
      onBlur: () => {
        //ensure that the inputs field holds the active values, onBlur
        setDateRange(currentActiveDateRangeRef.current);
      },
      endAdornment: (
        <>
          {params.inputProps?.value && (
            <IconButton
              onClick={() => {
                switch (params.label) {
                  case 'From':
                    setDateRange([null, toDateFilter ? new Date(toDateFilter) : null]);
                    handleDateRangeChange({
                      from: undefined,
                      to: toDateFilter ?? undefined,
                      timespan: TimeSpan.CUSTOM
                    });
                    break;
                  case 'To':
                    setDateRange([fromDateFilter ? new Date(fromDateFilter) : null, null]);
                    handleDateRangeChange({
                      from: fromDateFilter ?? undefined,
                      to: undefined,
                      timespan: TimeSpan.CUSTOM
                    });
                    break;
                }
              }}
            >
              <Icon type="clear" />
            </IconButton>
          )}
          {params.InputProps?.endAdornment}
        </>
      )
    };
  };
  let timeSpanArray = Object.values(TimeSpan);
  let indexOfMaxTimeSpan;
  if (maxTimeSpan) {
    indexOfMaxTimeSpan = timeSpanArray.indexOf(maxTimeSpan);
  }
  timeSpanArray = timeSpanArray.splice(0, indexOfMaxTimeSpan || timeSpanArray.length);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        width: enableDynamicWidth ? 'auto' : 'max-content'
      }}
    >
      {!displayCustomDateRange && (
        <HighlightOnFirstRender enabled={timespan !== TimeSpan.CUSTOM}>
          <CustomTextField
            dataTestId={`${dataTestId}-${componentDataTestId}-timespan`}
            value={timespan || TimeSpan.LAST_30_DAYS}
            onChange={(event: any) => {
              const newTimespan = event.target.value as TimeSpan;
              const { from, to } = getFromToFilterFromTimespan(
                newTimespan,
                fromDateFilter,
                toDateFilter
              );
              setDateRange([from ? new Date(from) : null, to ? new Date(to) : null]);
              if (newTimespan === TimeSpan.CUSTOM) {
                setDisplayCustomDateRange(true);
              }
              customDispatch({
                action: updateDateFilter,
                actionParameters: { from, to, timespan: newTimespan },
                disableAllMessages: true
              });
            }}
            select
            label="Timespan"
          >
            {timeSpanArray.map((timespan, index) => (
              <MenuItem key={index} value={timespan}>
                {timespan}
              </MenuItem>
            ))}
          </CustomTextField>
        </HighlightOnFirstRender>
      )}

      <CalenderPickerWrapper
        displaydatepicker={displayCustomDateRange.toString()}
        width={enableDynamicWidth ? 'auto' : 'max-content'}
      >
        {displayCustomDateRange && (
          <DateRangePicker
            inputFormat="dd/MM/yyyy"
            value={dateRange}
            onChange={(newValue, keyboardInputValue) => {
              //update local state
              setDateRange(newValue);

              const startDate = newValue[0] ? startOfDay(newValue[0] as Date) : undefined;
              const endDate = newValue[1] ? endOfDay(newValue[1] as Date) : undefined;

              //if user manually inputs a date, we need to check if it is a valid date (Date and after/before the 'other' date, or undefined)
              if (keyboardInputValue?.length) {
                // Validate the input format first
                // If both of the dates are defined, check if startDate is before endDate
                const isInvalidDate =
                  !dateFormatRegex.test(keyboardInputValue) ||
                  (startDate && endDate && isBefore(endDate, startDate));

                if (isInvalidDate) {
                  return;
                }
              }

              handleDateRangeChange({
                from: startDate?.getTime?.() || undefined,
                to: endDate?.getTime?.() || undefined,
                timespan: TimeSpan.CUSTOM
              });
            }}
            renderInput={(startProps, endProps) => (
              <>
                <HighlightOnFirstRender enabled={fromDateFilter !== undefined}>
                  <CustomTextField
                    dataTestId={`${dataTestId}-${componentDataTestId}-from`}
                    {...startProps}
                    InputProps={getInputProps(startProps)}
                    sx={{ maxWidth: '10rem' }}
                  />
                </HighlightOnFirstRender>
                <Box sx={{ mx: 1 }} />
                <HighlightOnFirstRender enabled={toDateFilter !== undefined}>
                  <CustomTextField
                    dataTestId={`${dataTestId}-${componentDataTestId}-to`}
                    {...endProps}
                    InputProps={getInputProps(endProps)}
                    sx={{ maxWidth: '10rem' }}
                  />
                </HighlightOnFirstRender>
              </>
            )}
          />
        )}
        <ToggleButton
          value={displayCustomDateRange}
          selected={displayCustomDateRange}
          onClick={() => setDisplayCustomDateRange((prevState) => !prevState)}
        >
          <Icon type="calendar" />
        </ToggleButton>
      </CalenderPickerWrapper>
    </Box>
  );
};

export default FromToDatePicker;

const CalenderPickerWrapper = styled('div')(
  ({ theme, displaydatepicker, width }: { displaydatepicker: string; width: string } & any) => ({
    display: 'flex',
    position: 'relative',
    gap: '1rem',
    width: width,
    '&:before': {
      position: 'absolute',
      content: "''",
      width: '100%',
      borderRadius: '.05rem',
      height: '.1rem',
      backgroundColor: theme.palette.primary.main,
      right: 0,
      top: 'calc(100% + .5rem)',
      transform: 'translateY(-50%)',
      opacity: displaydatepicker === 'true' ? 0.5 : 0,
      transition: 'opacity .5s ease'
    }
  })
);
