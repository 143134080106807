import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from 'components/datagrid/Custom';
import { useEffect, useState } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { getThrottlingGroups } from 'redux/slices/throttlingGroup';
import { RootState, useSelector } from 'redux/store';
import { ThrottlingGroup } from '../../../@types/throttlingGroup';
import AddThrottlingGroupDialog from './AddThrottlingGroupDialog';
import { Typography } from '@mui/material';
import CustomButton from 'components/util/CustomButton';
import ThrottlingGroupCampaignsDialog from './ThrottlingGroupCampaignsDialog';
import { DataGridKey } from 'redux/slices/datagrid';
import { EditListItem } from 'components/datagrid/listItems/EditListItem';

const ThrottlingGroupsList = () => {
  const { throttlingGroups, isLoading: isThrottlingGroupLoading } = useSelector(
    (state: RootState) => state.throttlingGroup
  );
  const isLoading = isThrottlingGroupLoading.getThrottlingGroups;
  const customDispatch = useCustomDispatch();
  const [throttlingGroupToEdit, setThrottlingGroupToEdit] = useState<ThrottlingGroup | undefined>();

  useEffect(() => {
    customDispatch({ action: getThrottlingGroups, disableSuccessMessage: true });
  }, [customDispatch]);

  const [selectedThrottlingGroup, setSelectedThrottlingGroup] = useState<
    ThrottlingGroup | undefined
  >(undefined);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      type: 'number',
      valueGetter: (params) => {
        return params.row.id;
      },
      renderCell: (params) => {
        return params.row.id;
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'campaigns',
      headerName: 'Campaigns',
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        const campaignIds = params.row.campaignIds as number[];
        return campaignIds.length;
      },
      renderCell: (params) => {
        const campaignIds = params.row.campaignIds as number[];
        if (campaignIds.length <= 0) return <></>;

        const throttlingGroupId = params.row.id as string;
        const throttlingGroup = throttlingGroups.find(
          (throttlingGroup) => throttlingGroup.id === throttlingGroupId
        );
        return (
          <CustomButton
            dataTestId="throttling-group-list-campaigns"
            onClick={() => setSelectedThrottlingGroup(throttlingGroup)}
          >{`${campaignIds.length} campaign${campaignIds.length > 1 ? 's' : ''}`}</CustomButton>
        );
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 60,
      hideable: false,
      editable: false,
      align: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (cellValues) => {
        const throttlingGroup = cellValues.row as ThrottlingGroup;
        return (
          <>
            {/**
             * If the id is null, the group is the default group, and is not editable. '' tellts the dialog that the group is default
             */}
            <EditListItem onClick={() => setThrottlingGroupToEdit(throttlingGroup)} />
          </>
        );
      }
    }
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
        <Typography variant="body2">
          This page shows all throttling groups, which help control how often surveys are sent out.
        </Typography>
        <Typography variant="body2">
          When throttling is enabled, a survey is only sent to a respondent if they haven't received
          any surveys from other campaigns in the same group during the set number of days. By
          default, all campaigns are part of one common group. If no group is chosen, the campaign
          is independent and follows its own throttle settings.
        </Typography>
        <Typography variant="body2">
          Remember, throttling rules only apply to campaigns in the same group. Campaigns in
          different groups won't affect each other's survey sending frequency.
        </Typography>
      </div>

      <Box p={1} />
      <CustomDataGrid
        getRowId={(row) => row.id ?? row.name}
        dataGridKey={DataGridKey.ThrottlingGroups}
        autoHeight
        loading={isLoading}
        disableSelectionOnClick
        rows={throttlingGroups}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
        pageSize={25}
        pagination
      />
      <AddThrottlingGroupDialog
        isOpen={Boolean(throttlingGroupToEdit)}
        close={() => setThrottlingGroupToEdit(undefined)}
        fetchThrottlingGroupsOnSuccess
        throttlingGroupToEdit={throttlingGroupToEdit}
      />
      <ThrottlingGroupCampaignsDialog
        dataGridKey={DataGridKey.ThrottlingGroupCampaigns}
        throttlingGroup={selectedThrottlingGroup}
        resetThrottlingGroup={() => setSelectedThrottlingGroup(undefined)}
      />
    </>
  );
};

export default ThrottlingGroupsList;
